@import '~normalize.scss/normalize';

@import 'reset';
@import 'mixin';
@import 'typography';
@import 'layout';

:root ,
body {
  height: 100%;
  min-width: 375px;
}

img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
a {
  color: $blue;
  @media #{$minM} {
    transition: .4s;
    &:active:focus,
    &:focus ,
    &:active {
      outline: 0 none;
    }
    &:hover {
      opacity: .7;
    }
  }
}
.container {
  animation: fadeIn .5s ease 0s 1 normal;
  animation-fill-mode: backwards;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@keyframes fadeIn {
  0% {opacity: 0}
  100% {opacity: 1}
}