.width-xxl {
  margin: 0 auto;
  max-width: 1440px;
}
.width-xl {
  margin: 0 auto;
  max-width: 1280px;
}
.width-l {
  margin: 0 auto;
  max-width: 1024px;
  @media #{$maxM} {
    max-width: 480px;
  }
}
.width-m {
  margin: 0 auto;
  max-width: 864px;
  @media #{$maxM} {
    max-width: 480px;
  }
}
.width-s {
  margin: 0 auto;
  max-width: 780px;
  @media #{$maxM} {
    max-width: 480px;
  }
}
.width-xs {
  margin: 0 auto;
  max-width: 640px;
  @media #{$maxM} {
    max-width: 480px;
  }
}
