.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  font-size: 13px;
  position: absolute;
  top: 0;
  transition: 0.3s;
  width: 100%;
  z-index: 4;
  a,
  span {
    display: block;
    transition: 0.3s;
    white-space: nowrap;
  }
  .button {
    font-size: 14px;
    box-shadow: 0 2px 12px rgba(#000, 0.2);
    padding: 10px 20px;
  }
  &Nav-menu {
    a,
    span {
      color: #fff;
    }
  }
  &-logo a {
    padding: 0 12px 0 36px;
    img {
      background: #fff;
      padding: 4px;
    }
    @media #{$maxM} {
      padding: 16px 24px 21px;
      img {
        width: 80px;
      }
    }
  }
  @media #{$minL} {
    &-nav nav {
      align-items: center;
      display: flex;
    }
    &Nav {
      &-menu {
        align-items: center;
        display: flex;
        font-weight: bold;
        padding-right: 16px;
        & > li {
          cursor: pointer;
          padding: 0 10px;
          position: relative;
          &:hover {
            span {
              opacity: 0.6;
            }
            ul {
              max-height: 100vh;
              padding: 12px;
            }
          }
        }
        ul {
          background: #fff;
          border-radius: 2px;
          font-size: 12px;
          box-shadow: 0 0 12px rgba(#000, 0.2);
          left: 10px;
          padding: 0 12px;
          position: absolute;
          overflow: hidden;
          max-height: 0;
          white-space: nowrap;
          transition: 0.3s;
          top: calc(100% - 2px);
          a {
            color: #000;
            line-height: 1;
            opacity: 0.5;
            padding: 10px 8px;
            &:hover {
              background: linear-gradient(
                transparent 50%,
                rgba($yellow, 0.6) 50%,
                rgba($yellow, 0.6) 80%,
                transparent 80%
              );
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &Nav-tel {
    border-radius: 0 0 0 8px;
    font-size: 21px;
    display: flex;
    overflow: hidden;
    transition: 0.3s;
    a {
      background: $lightblue;
      color: #fff;
      font-weight: 600;
      line-height: 1;
      padding: 16px 20px 22px;
      @media #{$minM} {
        &:hover {
          background: rgba($lightblue, 0.1);
          color: $lightblue;
          opacity: 1;
        }
      }
      &:first-child {
        background: $blue;
        @media #{$minM} {
          &:hover {
            background: rgba($blue, 0.1);
            color: $blue;
          }
        }
      }
    }
    small {
      display: block;
      font-size: 11px;
      padding-bottom: 2px;
    }
    span {
      text-decoration: underline;
      transition: none;
    }
  }
  @media #{$maxL} {
    &-trigger {
      cursor: pointer;
      display: block;
      height: 66px;
      padding: 20px 24px;
      position: relative;
      z-index: 30;
      i {
        background: #fff;
        content: "";
        display: block;
        transition: 0.3s;
        height: 2px;
        width: 32px;
        &:nth-child(2) {
          margin: 12px 0;
        }
        &:first-child,
        &:last-child {
          position: absolute;
          left: 24px;
        }
        &:first-child {
          top: 20px;
        }
        &:last-child {
          bottom: 20px;
        }
      }
    }
    &-nav nav {
      background: rgba($darkblue, 0.9);
      bottom: 0;
      height: 100vh;
      position: fixed;
      top: 0;
      right: -100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      transition: 0.4s;
      width: 100%;
    }
    &Nav-menu {
      display: flex;
      flex-wrap: wrap;
      padding: 80px 24px;
      max-width: 400px;
      margin: 0 auto;
      span,
      & > li > a {
        font-weight: bold;
        padding: 8px 0;
      }
      & > li {
        margin-bottom: 16px;
        width: 50%;
      }
      ul {
        font-size: 12px;
        padding-left: 8px;
      }
      a {
        padding: 12px;
      }
    }
    #showNav:checked ~ .header-nav {
      nav {
        right: 0;
      }
      .header-trigger {
        i {
          &:nth-child(2) {
            opacity: 0;
          }
          &:first-child {
            top: 32px;
            transform: rotate(-45deg);
          }
          &:last-child {
            bottom: 32px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  &.aos-animate {
    animation: slide-down 0.4s linear 0s both;
    background: rgba(#fff, 0.8);
    position: fixed;
    transition: 0 0.4s;
    .headerNav-tel {
      border-radius: 0;
    }
    @media #{$minL} {
      .headerNav-menu span,
      .headerNav-menu a:not(.button) {
        color: $darkblue;
      }
    }
    @media #{$maxL} {
      .header-trigger i {
        background: $blue;
      }
      #showNav:checked ~ .header-nav .header-trigger i {
        background: #fff;
      }
    }
  }
  @keyframes slide-down {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(0);
    }
  }
}
