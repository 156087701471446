#snsTrigger {
  position: absolute;
  top: 0;
  height: 50vh;
  width: 40px;
  z-index: -1;
}
.footer {
  position: relative;
  z-index: 2;
  a {
    display: block;
  }
  @keyframes bus-move {
    0% {
      transform: translateX(200px);
    }
    100% {
      transform: translateX(-2600px);
    }
  }
  @keyframes bus-move-sp {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(-600px);
    }
  }
  @keyframes tire {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-720deg);
    }
  }
  &-inner {
    background: $bggray;
    position: relative;
    z-index: 1;
  }
  &-bus {
    opacity: 0.2;
    will-change: all;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    @media #{$maxM} {
      bottom: 52px;
    }
    div {
      position: relative;
      width: 420px;
      margin-left: auto;
      animation: bus-move 12s linear 2s infinite forwards;
      @media #{$maxM} {
        animation: bus-move-sp 5s linear 0s infinite forwards;
        width: 180px;
      }
    }
  }
  &Bus {
    &-wheel,
    &-tire {
      bottom: 0;
      left: 74px;
      position: absolute;
      width: 42px;
      @media #{$maxM} {
        left: 32px;
        width: 18px;
      }
      &.bk {
        left: 273px;
        @media #{$maxM} {
          left: 117px;
        }
      }
    }
    &-wheel {
      animation: tire 1s linear infinite forwards;
      @media #{$maxM} {
        animation: tire 1s linear infinite forwards;
      }
    }
  }
  &-links {
    font-size: 12px;
    font-weight: 500;
    line-height: 2;
    padding: 40px;
    position: relative;
    z-index: 1;
    @media #{$maxM} {
      font-size: 10px;
      line-height: 1.2;
      padding: 40px 16px 64px;
    }
    .width-l {
      display: flex;
      @media #{$maxM} {
        flex-wrap: wrap;
      }
      & > div {
        flex: 1;
        @media #{$maxM} {
          flex: 0 0 50%;
          padding: 0 8px 3em;
        }
      }
    }
    dl + dl {
      margin-top: 2em;
      @media #{$maxM} {
        margin-top: 3em;
      }
    }
    dt {
      font-weight: 600;
    }
    dd {
      padding-top: 2px;
      @media #{$maxM} {
        padding-top: 11px;
      }
    }
    a {
      color: $gray;
      padding-left: 2.2em;
      position: relative;
      &:before {
        content: "　ー";
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &-foot {
    background: $darkblue;
    color: #fff;
    padding: 8px 40px;
    position: relative;
    z-index: 1;
    @media #{$minM} {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    @media #{$maxM} {
      padding: 8px 24px;
    }
    a {
      color: #fff;
    }
  }
  &Foot {
    &-right {
      @media #{$minM} {
        align-items: center;
        display: flex;
      }
      ul {
        display: flex;
        font-size: 24px;
        @media #{$minM} {
          padding-right: 40px;
        }
        @media #{$maxM} {
          justify-content: center;
          line-height: 1;
        }
      }
      a {
        padding: 8px 18px;
      }
    }
    &-copy {
      @include en-font-family;
      font-size: 12px;
      font-weight: bold;
      @media #{$maxM} {
        font-size: 10px;
        text-align: center;
        padding-bottom: 8px;
      }
    }
  }
  &-top {
    background: rgba($darkblue, 0.9);
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 20px;
    line-height: 40px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 40px;
    z-index: 1;
  }
  &-sns {
    position: fixed;
    text-align: center;
    right: 8px;
    padding: 8px 0;
    top: 88px;
    transition: 0.3s;
    z-index: 1;
    @media #{$minM} {
      &.aos-animate {
        opacity: 0;
        right: -80px;
      }
    }
    li + li {
      margin-top: 8px;
    }
    a {
      background: rgba(#fff, 0.8);
      border-radius: 8px;
      font-size: 22px;
      line-height: 48px;
      padding: 0;
      text-align: center;
      width: 48px;
      i {
        color: #000;
        transition: 0.2s;
      }
      @media #{$maxM} {
        font-size: 16px;
        line-height: 38px;
        width: 38px;
      }
      @media #{$minM} {
        &:hover {
          background: #fff;
          transform: scale(1.1);
          border-radius: 12px;
          opacity: 1;
          i {
            transform: scale(1.1);
            &.fa-instagram {
              color: #b900b4;
            }
            &.fa-facebook-square {
              color: #4267b2;
            }
            &.fa-youtube {
              color: #ff0000;
            }
            &.fa-twitter {
              color: #00aced;
            }
          }
        }
      }
    }
  }
  &Sns-outer {
    @media #{$maxM} {
      .footer-sns {
        opacity: 0;
        right: -80px;
      }
      &.aos-animate .footer-sns {
        opacity: 1;
        right: 8px;
      }
    }
  }
  @keyframes footer-slide-down {
    0% {
      transform: translateY(0);
    }
    99% {
      transform: translateY(200px);
      position: fixed;
    }
    100% {
      transform: translateY(0);
      position: absolute;
    }
  }
  &-line {
    bottom: 0;
    color: #00b900;
    display: block;
    transition-duration: 0s !important;
    position: fixed;
    right: 0;
    z-index: 0;
    @media #{$minM} {
      display: none !important ;
    }
    @media #{$maxM} {
      z-index: 1;
    }
    &.aos-animate {
      @media #{$maxM} {
        animation: footer-slide-down 0.6s linear 0s both;
        bottom: 0;
      }
    }
    & > span {
      display: block;
      padding: 0 0 0 24px;
      position: relative;
      z-index: 1;
      @media #{$maxM} {
        padding: 0 0 0 12px;
      }
    }
    p {
      font-size: 11px;
      font-weight: bold;
      display: block;
      line-height: 1.4;
      padding: 8px 12px 8px;
      text-align: center;
      @media #{$maxM} {
        font-size: 11px;
        line-height: 1;
        padding: 4px 3px 8px;
      }
    }
    img {
      @media #{$maxM} {
        width: 42px;
      }
    }
    &:before {
      background: #fff;
      border-radius: 50%;
      bottom: -120px;
      box-shadow: 0 0 12px rgba(#000, 0.2);
      content: "";
      display: block;
      height: 224px;
      position: absolute;
      right: -100px;
      transition: 0.2s;
      width: 224px;
      z-index: 0;
      @media #{$maxM} {
        bottom: -60px;
        height: 130px;
        right: -52px;
        width: 130px;
      }
    }
    &:hover {
      opacity: 1;
      &:before {
        bottom: -100px;
        right: -100px;
        transform: scale(1.2);
        @media #{$maxM} {
          bottom: -56px;
          right: -56px;
        }
      }
    }
  }
  @media #{$maxM} {
    &-body {
      position: relative;
    }
    &-nav {
      background: $blue;
      bottom: 0;
      display: flex;
      left: 0;
      line-height: 1;
      height: 68px;
      position: fixed;
      text-align: center;
      transition: 0s 0.4s !important;
      width: 100%;
      z-index: 1;
      &.aos-animate {
        animation: footer-slide-down 0.6s linear 0s both;
      }
      a {
        color: #fff;
        display: block;
      }
      i {
        display: block;
        font-size: 18px;
      }
      small {
        font-size: 16px;
        display: block;
      }
      ul {
        display: flex;
        flex: 1;
        padding: 0 72px 0 0;
      }
      li {
        flex: 1;
        & + li div {
          border-left: 1px solid rgba(#fff, 0.5);
        }
        i {
          padding-bottom: 4px;
        }
        span {
          align-items: center;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          justify-content: center;
          height: 22px;
          img {
            margin: 0;
          }
        }
        a {
          align-items: center;
          display: flex;
          height: 100%;
          padding: 10px 0;
          white-space: nowrap;
          div {
            padding: 0 4px;
            width: 100%;
          }
        }
      }
    }
    &Nav-top {
      background: $darkblue;
      padding: 12px 8px;
      i {
        font-size: 13px;
      }
      small {
        font-size: 10px;
        line-height: 1;
      }
    }
  }
}
