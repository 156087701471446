.breadcrumb {
  border-bottom: solid #e3e3e3 1px;
  border-top: solid #e3e3e3 1px;
  font-size: 12px;
  padding: 20px 40px;
  @media #{$minM} {
    align-items: center;
    display: flex;
  }
  @media #{$maxM} {
    @include truncate;
    padding: 16px 24px;
    li {
      display: inline-block;
      &:last-child {
        display: inline;
      }
    }
  }
  i.fa-angle-right {
    display: block;
    line-height: 1;
    padding: 2px 5px 0;
  }
  i.fa-home {
    color: $lightblue;
    padding-right: 2px;
  }
  a {
    text-decoration: underline;
    @media #{$minM} {
      &:hover {
        text-decoration: none;
      }
    }
  }
}