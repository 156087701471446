@import url('https://fonts.googleapis.com/css2?family=Lato&family=Noto+Sans+JP:wght@400;500;700&display=swap');


$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';

$yakuhanjp-noto-eot-use: false;
$yakuhanjp-noto-cdn-use: false;
$yakuhanjp-noto-font-dir: "~yakuhanjp/dist/fonts";
@import "~yakuhanjp/src/scss/yakuhanjp-noto.scss";

$minL: "all and (min-width: 1160px)";
$maxL: "all and (max-width: 1159px)";

$minM: "all and (min-width: 769px)";
$maxM: "all and (max-width: 768px)";

$minS: "all and (min-width: 440px)";
$maxS: "all and (max-width: 439px)";

@media #{$maxL} {
  .hideMaxL { display: none!important; }
}
@media #{$minL} {
  .hideMinL { display: none!important; }
}
@media #{$maxM} {
  .hideMaxM { display: none!important; }
}
@media #{$minM} {
  .hideMinM { display: none!important; }
}
@media #{$maxS} {
  .hideMaxS { display: none!important; }
}
@media #{$minS} {
  .hideMinS { display: none!important; }
}
@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  width:100%;
  white-space: nowrap;
}
@mixin overlay {
  bottom: 0;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
//typography
@mixin base-font-family {
  font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
}
@mixin serif-font-family {
  font-family: 'Noto Serif JP', serif;
}
@mixin en-font-family {
  font-family: 'Lato', 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
}
@mixin yaku-font-family {
  font-family: YakuHanJP_Noto, 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Arial, "メイリオ", Meiryo, sans-serif;
  font-feature-settings: "pkna" 1;
}
//colors
$yellow: #eff421;
$lightblue: #1c9fd6;
$blue: #0068b6;
$darkblue: #003759;
$bggray: #efeeec;
$gray: #333333;
$lightgray: #f7f7f7;
$red: #d80000;

$colors: (
  yellow: $yellow,
  lightblue: $lightblue,
  blue: $blue,
  bggray: $bggray,
  gray: $gray,
  lightgray: $lightgray,
  red: $red,
);