div.wp-pagenavi {
  display: flex;
  justify-content: center;
  @media #{$maxM} {
    align-items: center;
    flex-wrap: wrap;
  }
  a,
  span {
    background: rgba($gray, 0.8);
    border: 0;
    border-radius: 2px;
    color: #fff;
    display: block;
    font-size: 16px;
    padding: 2px 18px;
    margin: 0 4px;
    @media #{$maxM} {
      font-size: 12px;
      padding: 4px 12px;
    }
    &.current {
      border: 0;
      opacity: 0.3;
    }
  }
  span.pages,
  span.extend {
    background: none;
    color: rgba($gray, 0.8);
  }
  span.pages {
    font-weight: 600;
    @media #{$maxM} {
      text-align: center;
      order: 40;
      padding: 16px 0 0;
      margin: 0;
      width: 100%;
    }
  }
  span.extend {
    @media #{$maxM} {
      padding: 4px 0;
      margin: 0;
    }
  }
}
