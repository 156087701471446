:root ,
body {
  color: #000;
  font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
  -moz-font-smoothing: grayscale;
	-moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt" 1;
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.7;
  @include base-font-family;
  @media #{$maxM} {
    line-height: 1.8;
  }
}
