.button,
.button-border {
  background: $lightblue;
  border: 2px solid $lightblue;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  display: block;
  padding: 18px 72px;
  text-align: center;
  transition: 0.3s;
  @media #{$maxM} {
    font-size: 12px;
    padding: 16px;
    max-width: 80%;
    width: 240px;
  }
  @media #{$minM} {
    &:hover,
    &:focus {
      background: $blue;
      border-color: $blue;
      opacity: 1;
    }
  }
}

.button-top {
  background: #1c9fd6;
  border: 2px solid #1c9fd6;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  display: block;
  padding: 18px 72px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}
@media all and (max-width: 768px) {
  .button-top {
    font-size: 12px;
    padding: 16px;
    max-width: 80%;
    width: 240px;
  }
}
@media all and (min-width: 769px) {
  .button-top:hover,
  .button-top:focus {
    background: #0068b6;
    border-color: #0068b6;
    opacity: 1;
  }
}

.button-top:after,
.button-gray:after {
  content: "";
  display: block;
  width: 0px;
  height: 15px;
  bottom: 5px;
  right: 5px;
  position: absolute;
  border-right: 0px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
}

.button-gray {
  background: #aaaaaa;
  border: 2px solid #aaaaaa;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  display: block;
  padding: 18px 72px;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
}
@media all and (max-width: 768px) {
  .button-gray {
    font-size: 12px;
    padding: 16px;
    max-width: 80%;
    width: 240px;
  }
}
@media all and (min-width: 769px) {
  .button-gray:hover,
  .button-gray:focus {
    background: #666;
    border-color: #666;
    opacity: 1;
  }
}

.button-border {
  background: transparent;
  color: $lightblue;
  @media #{$minM} {
    &:hover,
    &:focus {
      background: transparent;
      border-color: $blue;
      color: $blue;
      opacity: 1;
    }
  }
}
